.sortable-link {
  color: $gray;
  // cursor: pointer;

  .icon-sort {
    margin-left: 4px;
    width: 12px;
    height: 12px;
    fill: $gray;
  }

  &:hover, &:focus {
    text-decoration: none;

    .icon-sort {
      fill: $gray-dark;
    }
  }

  &__link-title {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}